import { get, post, del } from "@/utils/https";

// 贷款用途
export function loanUseEnums() {
  return get(`/api/v1/common/cus/infra/dic/loan-purpose`);
}
// 行业
export function industryEnums() {
  return get(`/api/v1/common/cus/infra/dic/industry`);
}
// 行驶证识别（预进件）
export function ocrLicense(data) {
  if (!data.prePlatOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/ocr-driving-licence/${data.prePlatOrderNo}`,
    data
  );
}
// 申请订单创建
export function applyCreate(data) {
  return post(`/api/v1/common/cus/apply/wjf/wjf/apply`, data);
}
// 银行预留手机号验证码
export function authSmsCode(data) {
  return get(`/api/v1/common/cus/apply/wjf/wjf/apply/${data.mobile}`, data);
}

// 人脸对比
export function faceCompare({ prePlatOrderNo, url }) {
  if (!prePlatOrderNo) throw "预进件订单号错误";
  return post(`/api/v1/common/cus/apply/wjf/face/idcard/${prePlatOrderNo}`, {
    url
  });
}
// ocr识别
export function ocrIdentify(data) {
  return post(`/api/v1/common/cus/apply/wjf/ocr/idcard`, data);
}
// 识别提交认证
export function ocrIdentifySubmit(data) {
  if (!data.prePlatOrderNo) throw "订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/ocr/idcard/${data.prePlatOrderNo}`,
    data
  );
}

// 担保人添加
export function guarantorAdd(data) {
  if (!data.prePlatOrderNo) throw "订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/pre/guarantor/${data.prePlatOrderNo}`,
    data
  );
}
// 获取担保人
export function guarantorList(prePlatOrderNo) {
  return get(`/api/v1/common/cus/apply/wjf/guarantor/${prePlatOrderNo}`);
}

// 列表
export function orderList() {
  return post(`/api/v1/common/cus/apply/wjf/apply/list`);
}

// 订单详情
export function orderDetailInfo(bizNo) {
  if (!bizNo) throw "订单号错误";
  return get(`/api/v1/common/cus/apply/wjf/${bizNo}`);
}

// 创建个人信息
export function createPersonalInfo(data) {
  return post(`/api/v1/common/cus/apply/wjf/customer/${data.bizNo}`, data);
}
// 获取个人信息
export function getPersonalInfo(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/customer/info/${bizNo}`);
}
// 创建工作信息
export function createWorkInfo(data) {
  return post(`/api/v1/common/cus/apply/wjf/customer/work/${data.bizNo}`, data);
}
// 获取工作信息
export function getWorkInfo(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/customer/work/info/${bizNo}`);
}

// 发起评估
export function assessVehicle(bizNo) {
  return post(`/api/v1/common/cus/apply/wjf/evaluation/${bizNo}`);
}
// 车辆评估枚举
export function assessEnums() {
  return get(`/api/v1/common/cus/apply/wjf/getCarEvalPicTypeList`);
}
// 发起评估v2
export function assessVehiclev2(bizNo, data) {
  return post(`/api/v1/common/cus/apply/wjf/car/eval/${bizNo}`, data);
}
// 评估渠道状态
export function assessChannel(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/merchant/eval/config/${bizNo}`);
}

// 创建车辆信息
export function createVehicleInfo(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/customer/vehicle/${data.bizNo}`,
    data
  );
}
// 进件材料配置
export function materialsConfigList(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/${bizNo}/material/list`);
}
// 保存
export function saveMaterialsImg(data) {
  return post(`/api/v1/common/cus/apply/wjf/${data.bizNo}/material`, data);
}
// 删除
export function delMaterialsImg(id) {
  return del(`/api/v1/common/cus/apply/wjf/${id}/material/image`);
}
// 更改进件材料退回的状态
export function changeReturnMaterialStatus(bizNo) {
  return post(`/api/v1/common/cus/apply/wjf/update/material/${bizNo}`);
}
// 发起授信申请
export function submitApplyReq(data) {
  return post(`/api/v1/common/cus/apply/wjf/credit-apply`, data, null, null, {
    timeout: 300 * 1000
  });
}
// 退回后再次发起授信申请
export function reSubmitApplyReq(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/re/credit-apply`,
    data,
    null,
    null,
    {
      timeout: 300 * 1000
    }
  );
}
// 绑卡状态
export function bindCardStatus(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/protocol/bind/card/status/${bizNo}`);
}
// 资方银行枚举
export function bankEnums() {
  return get(`/api/v1/common/cus/infra/withold/banks/WJF`);
}
// 发送验证码
export function sendBindCardSms(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/send/protocol/sms/${data.bizNo}`,
    data
  );
}
// 提交信息
export function bindCardSubmit(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/protocol/bind/card/${data.bizNo}`,
    data
  );
}
// 获取委托授权书
export function authorizationUrl(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/entrusted/${bizNo}`);
}

// 补充担保人
// 补充状态
export function guaChecked(signKey) {
  return get(`/api/v1/common/cus/guarantor/check`, { signKey });
}
// 担保人的申请人信息
export function guaInfo(signKey) {
  return get(`/api/v1/common/cus/guarantor/customer-info`, { signKey });
}
// 提交担保人信息
export function submitGuaInfo(data) {
  return post(
    `/api/v1/common/cus/guarantor/create?signKey=${data.signKey}`,
    data,
    null,
    null,
    {
      timeout: 120 * 1000
    }
  );
}
